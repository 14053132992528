import React from "react";

export default ({ data }) => {
  return (
    <React.Fragment>
      {data && (
        <div className="flex-container right">
          {data.authorImage && (
            <div className="author-avatar-big">
              <img src={data.authorImage.url} className="rounded-img" />
            </div>
          )}
          <div className="author-details-container">
            <h6 className="h6">{data.authorName}</h6>
            <div className="small-copy">{data.jobTitle}</div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
