import React from "react";
import { graphql } from 'gatsby'
import ReactMarkdown from "react-markdown";
import Author from "../components/blog/author";
import { resetWebflow, replaceContentWithImgSize } from "../utils/common";
import { HelmetDatoCms } from "gatsby-source-datocms";
import SocialShare from "../components/common/social-share";
import BlogTag from "../components/blog/tag";
import BlogPreview from "../components/blog/blogPreview";
import Notification from "../components/header/notification";
import Layout from "../layouts";

export default ({
  data: {
    blogPost: {
      blogTitle,
      publishedOn,
      featureImage,
      category,
      author,
      content,
      tags,
      seoMetaTags,
      notificationPage
    },
    allDatoCmsNotification: { edges: notifications },
    relatedBlogPosts
  }
}) => {  
  resetWebflow("5ba525b1e783e2b20a301a07");
  var replaceImgContent = replaceContentWithImgSize(content);
  const previewBlogTmpl =
    relatedBlogPosts &&
    relatedBlogPosts.edges.map(({ node }) => {
      return <BlogPreview data={node} />;
    });
  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <header className="main-header about">
        <div className="container grid">
          <SocialShare id={"6b76be8b-c8b9-f4b2-4c1c-1e1ccff08bf5"} />
          <div
            data-w-id="1cdb0609-5797-4ec2-2433-4b7250f7e631"
            className="post-container"
          >
            <div className="small-uppercase">{category.categoryName}</div>
            <h1>{blogTitle}</h1>
            <p>Published on {publishedOn}</p>
            <ReactMarkdown source={replaceImgContent} escapeHtml={false} />
            {tags.map(tag => (
              <BlogTag data={tag} />
            ))}
            <div className="sm-spacing _30" />
            <div className="divider" />
            <div className="sm-spacing" />
            <Author data={author} />
            <div className="lg-spacing" />
          </div>
          <div className="container">
            <div className="grid-container-3">
              {previewBlogTmpl}
            </div>
          </div>
        </div>
      </header>
    </Layout>
  );
};

export const query = graphql`
  query BlogPostQuery($slug: String!, $categorySlug: String!) {
    blogPost: datoCmsBlogPost(slug: { eq: $slug }) {
      id
      blogTitle
      publishedOn(formatString: "MMM D, YYYY H:mm A")
      featureImage {
        url
      }
      category {
        categoryName
      }
      author {
        id
        authorImage {
          url
        }
        authorName
        jobTitle
      }
      content
      tags {
        id
        slug
        tagName
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      notificationPage {
        id
        page
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
    relatedBlogPosts: allDatoCmsBlogPost(
      filter: { category: { slug: { eq: $categorySlug } }, slug: { ne: $slug } }
      limit: 3
    ) {
      edges {
        node {
          id
          blogTitle
          publishedOn(formatString: "MMM D, YYYY H:mm A")
          featureImage {
            url
          }
          author {
            authorImage {
              url
            }
            authorName
            jobTitle
          }
          slug
        }
      }
    }
  }
`;
