import React from "react";
import { Link } from "gatsby";

export default ({ data }) => {
  return (
    <Link to={`/blog/tag/${data.slug}`} className="blog_tag w-inline-block">
      <div>{ data.tagName}</div>
    </Link>
  );
};
